<template>
  <div v-if="isShow" class="exchange-dialog">
    <div class="wrap">
      <div class="title">燃料券兑换</div>
      <div class="ls-icons icon-close" @click="onClose"></div>

      <Form
        ref="paramsForm"
        class="form-box"
        :configs="formConfigs"
        :labelWidth="labelWidth"
        :paramsData="paramsData"
      >
        <template v-slot:hint>
          <div class="hint-box">
            <p>每个编号仅可兑换一次，兑换完成即失效;</p>
            <p>输入编号后，点击下方“确认兑换”；</p>
            <p>系统会自动将燃料券面值等额的燃料充到您的燃料余额中。</p>
          </div>
        </template>
      </Form>

      <div class="btns">
        <div class="ls-btns-block" @click="onConfirm">确认兑换</div>
        <div class="ls-btns-block spin" @click="onClose">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 兑换弹窗
 */
import Form from "@/components/common/Form";
import { gasExchange } from "@/api/interface/gas.js";

export default {
  name: "Exchange",
  components: {
    Form,
  },
  data() {
    return {
      isShow: false,
      labelWidth: 100,
      formConfigs: [
        {
          type: "Input",
          prop: "voucherCode",
          label: "燃料券编号",
          placeholder: "请输入燃料券编号",
          maxlength: 32,
          rules: [
            {
              required: true,
              message: "请输入燃料券编号",
              trigger: "blur",
            },
            // { len: 32, message: "无效燃料券编号", trigger: "blur" },
          ],
        },
        {
          type: "Slot",
          prop: "hint",
          label: "",
        },
      ],
      paramsData: {},
    };
  },
  methods: {
    // 显示
    onShow() {
      this.isShow = true;
      this.paramsData = {};
    },
    // 关闭
    onClose() {
      this.isShow = false;
    },
    // 确定
    onConfirm() {
      this.$refs["paramsForm"].submitForm().then((res) => {
        this.$confirm("确认兑换?", "提示", {
          type: "warning",
          center: true,
        })
          .then((_) => {
            gasExchange({
              voucherCode: this.$refs["paramsForm"].getFormData().voucherCode,
            }).then(() => {
              this.$emit("update-data", {});
              this.onClose();
              this.$message.success("兑换成功，燃料已补充。");
            });
          })
          .catch((_) => {});
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.exchange-dialog {
  z-index: 999;

  @extend %com-dialog-fixed;

  .wrap {
    @extend %v-h-center;
    width: 707px;
    padding: 32px 0 46px;
    background: #ffffff;
    text-align: center;

    .title {
      text-align: center;
      line-height: 25px;
      height: 25px;
      font-weight: bold;
      font-size: 18px;
      color: #3d3d3d;
      margin-bottom: 6px;
    }

    .btns {
      display: flex;
      width: 258px;
      align-items: center;
      justify-content: space-between;
      margin: auto;
    }

    .hint-box {
      font-size: 12px;
      color: #8a8a8a;
      text-align: left;
      p {
        padding: 0;
        margin: 0;
        line-height: 27px;
      }
    }

    .form-box {
      margin: 46px 100px;
    }
  }

  .icon-close {
    @extend %com-dialog-close;
  }
}
</style>
