<template>
  <section class="ls-container fuelmanage">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="main-section">
      <div class="card-view">
        <div class="ls-title">燃料管理</div>

        <div class="flex alignCenter arithmetic-box">
          <div>
            <div>可用燃料</div>
            <div class="num">{{ useableGas }}</div>
          </div>

          <div class="symbol">=</div>

          <div>
            <div>全部燃料</div>
            <div class="num">{{ totalGas }}</div>
          </div>

          <div class="symbol">－</div>

          <div>
            <div>结算中燃料</div>
            <div class="num">{{ settlementGas }}</div>
          </div>
        </div>

        <div class="flex btns-box">
          <div class="ls-btns-block" @click="onRecharge">充值</div>
          <div class="ls-btns-block" @click="onExchange">燃料券兑换</div>
          <div class="ls-btns-block spin" @click="onRemind">燃料剩余提醒</div>
        </div>
      </div>

      <div class="card-view">
        <div class="ls-title">燃料明细</div>

        <div class="flex query-form">
          <el-date-picker
            v-model="timesArr"
            size="large"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>

          <div class="btns">
            <div @click="submitQuery" class="ls-btns-block">查询</div>
            <div @click="reset" class="ls-btns-block spin">重置</div>
          </div>
        </div>

        <Empty v-if="noData"></Empty>
        <Table
          v-else
          :baseData="tableData"
          :tableHeader="tableHeader"
          :align="'center'"
          :currentPage="pageNo"
          :pageSize="pageSize"
          :total="total"
          :isShowPagination="true"
          @handleCurrent="handleCurrent"
        ></Table>
      </div>
    </div>

    <!-- 兑换 -->
    <Exchange ref="exchange" @update-data="updateData"></Exchange>

    <!-- 提醒 -->
    <Remind ref="remind" @update-data="updateData"></Remind>

    <HintDialog :item="hintItem" />
  </section>
</template>

<script>
/**
 * 燃料管理
 */
import BreadCrump from "@/components/common/BreadCrump";
import Empty from "@/components/common/Empty";
import Exchange from "@/components/wallet/Exchange";
import Remind from "@/components/wallet/Remind";

import { gasInfo, gasList } from "@/api/interface/gas";
import { mapMutations, mapGetters } from "vuex";
import { formatNumber } from "@/utils/common";
import dayjs from "dayjs";
import HintDialog from "@/components/common/HintDialog";

export default {
  name: "FuelManage",
  components: {
    BreadCrump,
    Empty,
    Exchange,
    Remind,
    HintDialog,
  },
  data() {
    return {
      breadcrumbList: [{ menuName: "我的钱包" }],
      timesArr: [],
      tableHeader: this.$tableHeader.wallet.detail,
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      noData: false,
      gasData: {},
      hintItem: { isShow: false },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    totalGas() {
      return formatNumber(this.gasData.usableTimes);
    },
    useableGas() {
      return formatNumber(
        parseInt(this.gasData.usableTimes || 0) - 
          parseInt(this.gasData.frozenTimes || 0)
      );
    },
    settlementGas() {
      return formatNumber(this.gasData.frozenTimes);
    },
    enterpriseAuthStatus() {
      return this.userInfo.shop && this.userInfo.shop.auditStatus;
    },
  },
  methods: {
    ...mapMutations(["setGasInfo"]),
    // 充值
    onRecharge() {
      this.$router.push("/recharge");
    },
    //兑换
    onExchange() {
      if (this.enterpriseAuthStatus == 2) {
        this.$refs["exchange"].onShow();
      } else {
        this.authHint();
      }
    },
    //提醒
    onRemind() {
      if (this.enterpriseAuthStatus == 2) {
        this.$refs["remind"].onShow();
      } else {
        this.authHint();
      }
    },
    getGasInfo() {
      gasInfo().then((data) => {
        this.setGasInfo(data);
        this.gasData = data;
      });
    },
    getGasList() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      if (this.timesArr.length > 0) {
        params.startDate = dayjs(this.timesArr[0]).format("YYYY-MM-DD");
        params.endDate = dayjs(this.timesArr[1]).format("YYYY-MM-DD");
      }
      gasList(params).then((data) => {
        const { records = [], total } = data;
        if (!data || records.length == 0) {
          this.noData = true;
          return;
        } else {
          this.noData = false;
        }
        this.tableData = records;
        this.total = total;
      });
    },
    handleCurrent(val) {
      this.pageNo = val;
      this.getGasList();
    },
    submitQuery() {
      this.getGasList();
    },
    reset() {
      this.pageNo = 1;
      this.timesArr = [];
    },
    initData() {
      this.reset();
      this.getGasInfo();
      this.getGasList();
    },
    updateData() {
      this.initData();
    },
    authHint() {
      Object.assign(this.hintItem, {
        isShow: true,
        type: "error",
        iconCss: "icon-error-large",
        title: "您的账号暂未完成企业认证",
        content: "完成实名认证和企业认证即可解锁大量工作台的服务和功能~",
        btnTextL: "前往安全中心",
        btnFuncL: () => {
          this.$router.push({ name: "Account" });
        },
        btnTextR: "返回工作台首页",
        btnFuncR: () => {
          this.$router.push({ name: "Index" });
        },
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/el/_input.scss";
@import "@/scss/el/_table.scss";

.fuelmanage {
  .card-view {
    margin-top: 16px;
    width: 100%;
    background: #ffffff;
    padding: 25px 35px 35px 35px;

    .arithmetic-box {
      margin-top: 45px;
      font-size: 18px;
      color: #3d3d3d;
      .symbol {
        font-size: 36px;
        margin: 0 70px;
      }

      .num {
        margin-top: 15px;
        font-size: 36px;
        font-weight: bold;
        color: #0846ac;
      }
    }

    .btns-box {
      margin-top: 45px;

      div {
        margin-right: 42px;
      }
    }

    .query-form {
      margin: 15px 0 20px 0;
      .ls-btns-block {
        margin-right: 18px;
        vertical-align: middle;
        display: inline-block;
        height: 35px;
        &:first-child {
          margin-left: 24px;
        }
      }
      .btns {
        position: relative;
        .ls-btns-block {
          @extend %vertical-center;
          position: relative;
        }
      }
    }
  }
}
</style>
