import * as API from '@/api/request';

const comUrl = "/v1/workbench/gas/";

export function gasInfo() {
  return API.post(`${comUrl}info`);
}

export function gasList(params) {
  return API.post(`${comUrl}detail`, params);
}

export function gasExchange(params) {
  return API.post(`${comUrl}exchange`, params);
}

export function gasAlarmInfo() {
  return API.get(`${comUrl}alarmSetting`);
}

export function gasSetAlarm(params) {
  return API.post(`${comUrl}alarmSetting`, params);
}

export function gasCloseAlarm() {
  return API.post(`${comUrl}closeAlarm`);
}