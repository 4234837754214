<template>
  <div v-if="isShow" class="remind-dialog">
    <div class="wrap">
      <div class="title">燃料剩余提醒设置</div>
      <div class="ls-icons icon-close" @click="onClose"></div>

      <div class="text">
        当钱包中剩余可用燃料达到您设置的以下的数量时，将会发送通知提醒您及时进行燃料补充。
      </div>

      <Form
        ref="paramsForm"
        class="form-box"
        :configs="formConfigs"
        :labelWidth="labelWidth"
        :paramsData="paramsData"
      >
        <template v-slot:type>
          <div>
            <div @click="onCheck(0)" class="flex alignCenter notice-type">
              <div class="block-box flex justifyCenter alignCenter">
                <div v-if="checkType === 0" class="checked"></div>
              </div>
              短信通知
              <span class="hint-box"
                >&nbsp;&nbsp;短信将发送至企业认证提供的联系人手机号。</span
              >
            </div>
            <div @click="onCheck(1)" class="flex alignCenter notice-type">
              <div class="block-box flex justifyCenter alignCenter">
                <div v-if="checkType === 1" class="checked"></div>
              </div>
              邮件通知
              <span class="hint-box"
                >&nbsp;&nbsp;邮件将发送至企业认证提供的联系人邮箱。</span
              >
            </div>
          </div>
          <div class="hint-box">
            注意：剩余提醒仅可对一个数值生效；新设置的提醒值生效后，原先设置的数值将不再触发提醒通知。
          </div>
        </template>
      </Form>

      <div v-if="isValidate && hasCloseAlaram" class="validate-notice">
        {{ validateNotice }}
      </div>

      <div class="btns">
        <div class="ls-btns-block" @click="onConfirm">确认设置</div>
        <div class="ls-btns-block spin" @click="onClose">关闭</div>
      </div>

      <el-button
        v-if="hasCloseAlaram"
        class="m-t-15 center-wrap"
        type="text"
        @click="onCloseRemind"
        >关闭提醒服务</el-button
      >
    </div>
  </div>
</template>

<script>
/**
 * 提醒弹窗
 */
import Form from "@/components/common/Form";
import { gasAlarmInfo, gasSetAlarm, gasCloseAlarm } from "@/api/interface/gas";
import { NOTICE_TYPE } from "@/constants/";

export default {
  name: "Remind",
  components: {
    Form,
  },
  data() {
    return {
      isShow: false,
      labelWidth: 150,
      formConfigs: [
        {
          type: "Input",
          prop: "num",
          label: "燃料剩余小于等于",
          placeholder: "请输入燃料剩余提醒值(整数）",
          maxlength: 8,
          inputFunc: (val) => {
            const pattern = /^[1-9][0-9]*$/;
            if (!pattern.test(val)) {
              val = "";
            }
            this.$refs["paramsForm"].setParamsField("num", val);
          },
          rules: [
            {
              required: true,
              message: "请输入燃料剩余提醒值",
              trigger: "blur",
            },
          ],
        },
        {
          type: "Slot",
          prop: "type",
          label: "提醒通知方式",
          rules: [
            {
              required: true,
              message: "请选择提醒通知方式",
              trigger: "change",
            },
          ],
        },
      ],
      checkType: 0,
      paramsData: {
        num: null,
        type: 0,
      },
      hasCloseAlaram: false,
      isValidate: false,
    };
  },
  computed: {
    validateNotice() {
      if (this.paramsData.type < 0) {
        return "";
      }
      return `当前生效服务：${NOTICE_TYPE[this.paramsData.type]}通知；提醒值为${
        this.paramsData.num
      }。`;
    },
  },
  methods: {
    // 选择
    onCheck(type) {
      this.checkType = type;
    },
    // 显示
    onShow() {
      this.isShow = true;
      this.paramsData = {
        num: null,
        type: 0,
      };
      this.checkType = 0;

      this.getGasAlarmInfo();
    },
    // 关闭
    onClose() {
      this.isShow = false;
    },
    // 确定
    onConfirm() {
      this.$refs["paramsForm"].submitForm().then((res) => {
        this.$confirm("确认设置提醒服务", "提示", {
          type: "warning",
          center: true,
        }).then((_) => {
          gasSetAlarm({
            gasNotifyThreshold: this.$refs["paramsForm"].getFormData().num,
            notifyType: this.checkType,
          }).then(() => {
            this.onClose();
            this.$emit("update-data", {});
            this.$message.success("设置成功，燃料剩余提醒已生效。");
          });
        });
      });
    },
    // 关闭提醒
    onCloseRemind() {
      this.$confirm("确认关闭提醒服务？", "提示", {
        type: "warning",
        center: true,
      })
        .then((_) => {
          gasCloseAlarm().then(() => {
            this.onClose();
            this.$emit("update-data", {});
            this.$message.warning("关闭成功，您将不会再收到燃料剩余提醒通知。");
          });
        })
        .catch((_) => {});
    },
    getGasAlarmInfo() {
      gasAlarmInfo().then((data) => {
        this.paramsData.num = data.gasNotifyThreshold;
        this.paramsData.type = this.checkType = data.notifyType || 0;
        this.hasCloseAlaram = data.needNotify > 0;
        this.isValidate = data.gasNotifyThreshold > 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/el/_form.scss";
.remind-dialog {
  z-index: 999;

  @extend %com-dialog-fixed;
  .notice-type {
    cursor: pointer;
  }
  .wrap {
    @extend %v-h-center;
    padding: 32px 100px 46px;
    background: #ffffff;
    min-width: 860px;

    .title {
      text-align: center;
      line-height: 25px;
      height: 25px;
      font-weight: bold;
      font-size: 18px;
      color: #3d3d3d;
      margin-bottom: 6px;
    }

    .btns {
      display: flex;
      width: 258px;
      align-items: center;
      justify-content: space-between;
      margin: auto;
    }

    .hint-box {
      font-size: 12px;
      color: #8a8a8a;
      text-align: left;
    }

    .text {
      font-size: 12px;
      color: #8a8a8a;
      margin: 15px 0 30px 0;
    }

    .form-box {
      margin: 27px 0 46px;
    }

    .m-t-15 {
      margin-top: 15px;
    }
    .center-wrap {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .block-box {
      width: 14px;
      height: 14px;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #000000;
      margin-right: 10px;
      cursor: pointer;

      .checked {
        width: 6px;
        height: 6px;
        background: #1d8db9;
        border-radius: 50%;
      }
    }
  }

  .icon-close {
    @extend %com-dialog-close;
  }

  .validate-notice {
    font-size: 12px;
    color: #409eff;
    text-align: center;
    margin: 0 auto 21px;
  }
}
</style>
